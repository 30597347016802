import {commonSelectors as common} from '@gisatcz/ptr-state';
import {createSelector} from 'reselect';

const getSubstate = state => state.insar.visualizations;

const getActive = common.getActive(getSubstate);
const getActiveKey = common.getActiveKey(getSubstate);
const getAll = common.getAll(getSubstate);

const getActiveVisualizationOrderingByType = createSelector(
	[getActive, (state, type) => type],
	(activeModel, type) => {
		return activeModel?.data?.orderingByType?.[type] || null;
	},
);

export default {
	getSubstate,

	getActive,
	getActiveKey,
	getAll,

	getActiveVisualizationOrderingByType,
};
