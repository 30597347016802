import createCachedSelector from 're-reselect';
import {forEach as _forEach, orderBy as _orderBy} from 'lodash';
import visualizationsSelectors from '../Visualizations/selectors';
import utils from '../../../utils';

const getSubstate = state => state.insar.interferometryData;
const getAllByTypeByTrackKeyFeatures = state =>
	state.insar.interferometryData.byTypeByTrackKeyFeatures;
const getAllByTypeByFeatureKey = state =>
	state.insar.interferometryData.byTypeByFeatureKey;

const getFeaturesByTypeAndTrackKey = createCachedSelector(
	[
		getAllByTypeByTrackKeyFeatures,
		(state, type) => type,
		(state, type, trackKey) => trackKey,
	],
	(data, type, trackKey) => {
		return data?.[type]?.[trackKey];
	},
)((state, type, trackKey) => `${type}_${trackKey}`);

const getAllFeaturesByTypeAndTrackKeys = createCachedSelector(
	[
		getAllByTypeByTrackKeyFeatures,
		(state, type) => type,
		(state, type, trackKeys) => trackKeys,
	],
	(data, type, trackKeys) => {
		let allFeatures = [];
		_forEach(trackKeys, trackKey => {
			const features = data?.[type]?.[trackKey];
			if (features?.length) {
				allFeatures = [...allFeatures, ...features];
			}
		});

		return allFeatures?.length ? allFeatures : null;
	},
)((state, type, trackKeys) => `${type}_${JSON.stringify(trackKeys)}`);

const getAllFeaturesByTypeAndTrackKeysSorted = createCachedSelector(
	[
		getAllFeaturesByTypeAndTrackKeys,
		visualizationsSelectors.getActiveVisualizationOrderingByType,
	],
	(features, sortingBy) => {
		if (features && sortingBy) {
			const {attribute, order, type} = sortingBy;
			const sortingFunction = utils.sortingFunctionsByType[type];

			if (attribute) {
				return _orderBy(
					features,
					[sortingFunction.bind(this, attribute) || attribute],
					[order || 'asc'],
				);
			} else {
				console.warn('No sorting due to missing attribute!');
				return features;
			}
		} else if (features) {
			console.warn('No sorting due to missing sortingBy!');
			return features;
		} else {
			return null;
		}
	},
)((state, type, trackKeys) => `${type}_${JSON.stringify(trackKeys)}`);

const getFeatureByTypeAndFeatureKey = createCachedSelector(
	[
		getAllByTypeByFeatureKey,
		(state, type) => type,
		(state, type, featureKey) => featureKey,
	],
	(data, type, featureKey) => {
		return data?.[type]?.[featureKey];
	},
)((state, type, featureKey) => `${type}_${featureKey}`);

export default {
	getSubstate,

	getAllByTypeByFeatureKey,
	getAllFeaturesByTypeAndTrackKeys,
	getAllFeaturesByTypeAndTrackKeysSorted,
	getFeaturesByTypeAndTrackKey,
	getFeatureByTypeAndFeatureKey,
};
