import {Provider} from '@gisatcz/ptr-state';

import createStore from './state/Store';
import {App} from './app';
import {appKey} from './constants/app';
import ReactDOM from 'react-dom/client';
import Faro from './Analytics';
import config from './config';

const {store} = createStore();

/**
 * Init analytics
 */
Faro.init({
	url: config.grafanaFaroServiceUrl,
	app: {
		name: appKey,
		// version: '1.0.0',
		environment: config.deploymentEnviroment,
	},
});

const Application = () => (
	<Provider store={store}>
		<App />
	</Provider>
);

function renderApp() {
	ReactDOM.createRoot(document.getElementById('root')).render(<Application />);
}

renderApp();
