import {commonSelectors} from '@gisatcz/ptr-state';
import {createSelector} from 'reselect';

const getSubstate = state => state.insar.types;

const getActive = commonSelectors.getActive(getSubstate);
const getActiveKey = commonSelectors.getActiveKey(getSubstate);

const getAll = commonSelectors.getAll(getSubstate);
const getByKey = commonSelectors.getByKey(getSubstate);

const getAvailableOrbitalTrajectoryKeys = createSelector([getByKey], model => {
	return model?.data?.availableOrbitalTrajectories || null;
});

const getAvailableVisualizations = createSelector([getByKey], model => {
	return model?.data?.availableVisualizations || null;
});

const getGroupByActive = createSelector([getActive], model => {
	return model?.data?.group || null;
});

const getGroupByKey = createSelector([getByKey], model => {
	return model?.data?.group || null;
});

const getActiveTypeAttributeSets = createSelector([getActive], model => {
	return model?.data?.attributeSets || null;
});

const getActiveTypeAvailableVisualizations = createSelector(
	[getActive],
	model => {
		return model?.data?.availableVisualizations || null;
	},
);

const getActiveTypeAttributesForTooltip = createSelector([getActive], model => {
	return model?.data?.attributesForTooltip || null;
});

const getActiveTypeChartUnavailable = createSelector([getActive], model => {
	return model?.data?.chartUnavailable || null;
});

export default {
	getSubstate,

	getAll,
	getActiveKey,
	getActiveTypeAttributesForTooltip,
	getActiveTypeAttributeSets,
	getActiveTypeAvailableVisualizations,
	getActiveTypeChartUnavailable,

	getAvailableOrbitalTrajectoryKeys,
	getAvailableVisualizations,

	getGroupByActive,
	getGroupByKey,
};
