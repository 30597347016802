import {connect} from '@gisatcz/ptr-state';

import Select from '../../state/Select';
import presentation from './presentation';

const mapStateToProps = state => {
	return {
		activeMapKey: Select.maps.getActiveMapKey(state),
		fullWidth: Select.components.get(state, 'controlPanel', 'minimised'),
		chartExpanded: Select.components.get(
			state,
			'controlPanel',
			'chartExpanded',
		),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(presentation);
