import classnames from 'classnames';
import {map as _map, find as _find, isEqual as _isEqual} from 'lodash';

import './style.scss';
import PropTypes from 'prop-types';

const formatInterval = (interval, bounds) => {
	bounds = bounds || [true, false];
	return (
		(bounds[0] ? '[' : '(') +
		interval[0] +
		', ' +
		interval[1] +
		(bounds[1] ? ']' : ')')
	);
};

const IntervalLineRailCell = ({
	cls,
	styleProperty,
	order,
	disabled,
	onClick,
}) => {
	const classes = classnames('insar-IntervalsLine-railCell', {
		'is-disabled': disabled,
	});

	return (
		<div
			key={order}
			className={classes}
			title={
				cls.name ? cls.name : formatInterval(cls.interval, cls.intervalBounds)
			}
			style={{background: cls[styleProperty]}}
			onClick={onClick.bind(this, cls.interval)}
		/>
	);
};

IntervalLineRailCell.propTypes = {
	cls: PropTypes.object,
	styleProperty: PropTypes.string,
	order: PropTypes.number,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
};

const IntervalLineRail = ({
	classes,
	longerTails,
	styleProperty,
	disabledIntervals,
	onCellClick,
}) => {
	const lineClasses = classnames('insar-IntervalsLine-rail', {
		'longer-tails': longerTails,
		'longer-tails-first': longerTails === 'first',
		'longer-tails-last': longerTails === 'last',
	});

	return (
		<div className={lineClasses}>
			{classes.map((cls, i) => {
				const {interval} = cls;
				const isDisabled = !!_find(disabledIntervals, disabledInterval =>
					_isEqual(disabledInterval, interval),
				);

				return (
					<IntervalLineRailCell
						key={i}
						onClick={onCellClick}
						cls={cls}
						styleProperty={styleProperty}
						order={i}
						disabled={isDisabled}
					/>
				);
			})}
		</div>
	);
};

IntervalLineRail.propTypes = {
	classes: PropTypes.array,
	longerTails: PropTypes.bool,
	styleProperty: PropTypes.string,
	disabledIntervals: PropTypes.object,
	onCellClick: PropTypes.func,
};

const IntervalLineLabels = ({classes}) => {
	return (
		<div className="insar-IntervalsLine-labels">
			<span>{classes[0].name ? classes[0].name : classes[0].interval[0]}</span>
			<span>
				{classes[classes.length - 1].name
					? classes[classes.length - 1].name
					: classes[classes.length - 1].interval[1]}
			</span>
		</div>
	);
};

IntervalLineLabels.propTypes = {
	classes: PropTypes.array,
};

const IntervalLineNoData = ({color, disabled, onClick}) => {
	const classes = classnames('insar-IntervalsLine-noDataCell', {
		'is-disabled': disabled,
	});

	return (
		<div className={classes} onClick={onClick.bind(this, !disabled)}>
			<div style={{background: color}} />
			<span>No data</span>
		</div>
	);
};

IntervalLineNoData.propTypes = {
	color: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
};

export const IntervalsLine = ({
	classes,
	noData,
	styleProperty,
	longerTails,
	onIntervalClick,
	onNoDataClick,
	disabledIntervals,
	disabledNoData,
}) => {
	return (
		<LegendContent>
			<div className="insar-IntervalsLine">
				<div className="insar-IntervalsLine-railWrapper">
					<IntervalLineRail
						classes={classes}
						longerTails={longerTails}
						styleProperty={styleProperty}
						disabledIntervals={disabledIntervals}
						onCellClick={onIntervalClick}
					/>
					<IntervalLineLabels classes={classes} />
				</div>
				{noData ? (
					<IntervalLineNoData
						color={noData}
						disabled={disabledNoData}
						onClick={onNoDataClick}
					/>
				) : null}
			</div>
		</LegendContent>
	);
};

IntervalsLine.propTypes = {
	classes: PropTypes.array,
	noData: PropTypes.string,
	styleProperty: PropTypes.string,
	longerTails: PropTypes.bool,
	onIntervalClick: PropTypes.func,
	onNoDataClick: PropTypes.func,
	disabledIntervals: PropTypes.bool,
	disabledNoData: PropTypes.bool,
};

export const ValuesLegendItem = ({value, color, disabled, onClick}) => {
	const classes = classnames('insar-LegendValuesItem', {
		'is-disabled': disabled,
	});

	return (
		<div className={classes} onClick={onClick.bind(this, value)}>
			<div style={{background: color}} />
			<span>{value}</span>
		</div>
	);
};

ValuesLegendItem.propTypes = {
	value: PropTypes.string,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
};

export const ValuesByColor = ({
	data,
	noData,
	noDataTitle,
	disabledValues = [],
	onClick,
}) => {
	return (
		<LegendContent>
			{_map(data, (item, key) => {
				return (
					<ValuesLegendItem
						key={key}
						value={item.name || key}
						color={item.fill}
						disabled={disabledValues.indexOf(key) > -1}
						onClick={onClick}
					/>
				);
			})}
			{noData ? (
				<ValuesLegendItem value={noDataTitle || 'No data'} color={noData} />
			) : null}
		</LegendContent>
	);
};

ValuesByColor.propTypes = {
	data: PropTypes.object,
	noData: PropTypes.string,
	noDataTitle: PropTypes.string,
	disabledValues: PropTypes.array,
	onClick: PropTypes.func,
};

export const LegendHeader = ({title, subtitle}) => {
	return (
		<div className="insar-LegendHeader">
			<div className="title">{title}</div>
			<div className="subtitle">{subtitle}</div>
		</div>
	);
};

LegendHeader.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
};

export const LegendContent = ({children}) => {
	return <div className="insar-LegendContent">{children}</div>;
};

LegendContent.propTypes = {
	children: PropTypes.node,
};
