import {commonActions} from '@gisatcz/ptr-state';
import ActionTypes from '../../../constants/ActionTypes';
import Select from '../../Select';

const setActiveKeys = commonActions.setActiveKeys(
	ActionTypes.INSAR.ORBITAL_TRAJECTORIES,
);

const updateStateFromView = commonActions.updateSubstateFromView(
	ActionTypes.INSAR.ORBITAL_TRAJECTORIES,
);
const updateStore = commonActions.updateStore(
	Select.insar.orbitalTrajectories.getSubstate,
	ActionTypes.INSAR.ORBITAL_TRAJECTORIES,
);

export default {
	setActiveKeys,
	updateStateFromView,
	updateStore,
};
