import {commonSelectors} from '@gisatcz/ptr-state';
import {flatten as _flatten} from 'lodash';
import {createSelector} from 'reselect';

const getSubstate = state => state.insar.orbitalTrajectories;

const getActiveKeys = commonSelectors.getActiveKeys(getSubstate);
const getActiveModels = commonSelectors.getActiveModels(getSubstate);
const getAll = commonSelectors.getAll(getSubstate);

const getActiveTrajectoriesTracks = createSelector(
	[getActiveModels],
	trajectories => {
		if (trajectories) {
			const tracks = [];
			trajectories.forEach(trajectory => tracks.push(trajectory.data.tracks));

			return _flatten(tracks);
		} else {
			return null;
		}
	},
);

export default {
	getSubstate,

	getActiveKeys,
	getActiveModels,
	getActiveTrajectoriesTracks,
	getAll,
};
