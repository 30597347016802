import {isArray as _isArray, forEach as _forEach} from 'lodash';
import ActionTypes from '../../../constants/ActionTypes';

const INITIAL_STATE = {
	byTypeByTrackKeyFeatures: {},
	byTypeByFeatureKey: {},
};

const addByTypeByTrackKey = (state, type, trackKey, features) => {
	return {
		...state,
		byTypeByTrackKeyFeatures: {
			...state.byTypeByTrackKeyFeatures,
			[type]: {
				...state.byTypeByTrackKeyFeatures[type],
				[trackKey]: features,
			},
		},
	};
};

const addByTypeByFeatureKey = (
	state,
	type,
	trackKey,
	fidColumnName,
	features,
	fid,
) => {
	if (_isArray(features)) {
		let data = {};

		_forEach(features, feature => {
			const id = fid || feature.id || feature.properties[fidColumnName];
			data[id] = {
				...feature,
				properties: {
					...feature.properties,
					trackKey,
					[fidColumnName || 'id']: id,
				},
			};
		});

		return {
			...state,
			byTypeByFeatureKey: {
				...state.byTypeByFeatureKey,
				[type]: state.byTypeByFeatureKey[type]
					? {
							...state.byTypeByFeatureKey[type],
							...data,
						}
					: data,
			},
		};
	} else {
		const feature = features;
		const id = fid || feature.id || feature.properties[fidColumnName];
		return {
			...state,
			byTypeByFeatureKey: {
				...state.byTypeByFeatureKey,
				[type]: {
					...state.byTypeByFeatureKey[type],
					[id]: state.byTypeByFeatureKey[type][id]
						? {
								...state.byTypeByFeatureKey[type][id],
								properties: {
									...state.byTypeByFeatureKey[type][id].properties,
									...feature.properties,
								},
							}
						: feature,
				},
			},
		};
	}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.INSAR.INTERFEROMETRY_DATA.ADD_BY_TYPE_BY_FEATURE_KEY:
			return addByTypeByFeatureKey(
				state,
				action.trackType,
				action.trackKey,
				action.fidColumnName,
				action.features,
				action.fid,
			);
		case ActionTypes.INSAR.INTERFEROMETRY_DATA.ADD_BY_TYPE_BY_TRACK_KEY:
			return addByTypeByTrackKey(
				state,
				action.trackType,
				action.trackKey,
				action.features,
			);
		default:
			return state;
	}
};
