import {connect} from '@gisatcz/ptr-state';

import Action from '../../state/Action';
import Select from '../../state/Select';

import presentation from './presentation';

const mapStateToProps = state => {
	return {
		open: Select.components.get(state, 'introOverlay', 'open'),
		hidden: Select.components.get(state, 'controlPanel', 'minimised'),
		chartExpanded: Select.components.get(
			state,
			'controlPanel',
			'chartExpanded',
		),
		dataModeChosen: Select.app.getLocalConfiguration(
			state,
			'data.interferometry.dataModeChosen',
		),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		changeState: value => {
			dispatch(Action.components.set('introOverlay', 'open', value));
		},
		chooseDataMode: value => {
			dispatch(Action.insar.chooseDataMode(value));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(presentation);
