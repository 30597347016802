import {connect} from '@gisatcz/ptr-state';

import Action from '../../state/Action';
import Select from '../../state/Select';

import presentation from './presentation';

const mapStateToProps = state => {
	return {
		dataMode: Select.app.getLocalConfiguration(
			state,
			'data.interferometry.dataMode',
		),
		fullWidth: Select.components.get(state, 'controlPanel', 'minimised'),
		chartExpanded: Select.components.get(
			state,
			'controlPanel',
			'chartExpanded',
		),
		availableBackgroundLayers: Select.insar.getAvailableBackgroundLayers(state),
		availableWmsLayers: Select.insar.getAvailableWmsLayers(state),
		activeTypeKey: Select.insar.types.getActiveKey(state),
		activeOrbitalTrajectoryKeys:
			Select.insar.orbitalTrajectories.getActiveKeys(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setBackgroundLayer: definition => {
			dispatch(Action.insar.setInterferometryMapBackgroundLayer(definition));
		},
		addWmsLayer: key => {
			dispatch(Action.insar.addInterferometryWmsLayer(key));
		},
		removeWmsLayer: key => {
			dispatch(Action.insar.removeInterferometryWmsLayer(key));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(presentation);
