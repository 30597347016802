import {
	forIn as _forIn,
	cloneDeep as _cloneDeep,
	isEqual as _isEqual,
} from 'lodash';
import {Action as CommonAction, commonActions} from '@gisatcz/ptr-state';
import ActionTypes from '../constants/ActionTypes';
import Select from './Select';

// Actions
import interferometryDataActions from './insar/InterferometryData/actions';
import typesActions from './insar/Types/actions';
import orbitalTrajectoriesActions from './insar/OrbitalTrajectories/actions';
import visualizationsActions from './insar/Visualizations/actions';

// insar specific data
import typesData from '../data/insar/types';
import orbitalTrajectoriesData from '../data/insar/orbitalTrajectories';
import visualizationsData from '../data/insar/visualizations';

// metadata
import attributesData from '../data/attributes/attributes';
import attributeSetsData from '../data/attributes/attributeSets';
import stylesData from '../data/styles/styles';

const filteredOutStyle = {
	fillOpacity: 0,
	outlineOpacity: 0,
};

function useInterferometryMapData(forceLoad) {
	return (dispatch, getState) => {
		const state = getState();
		const fidColumnName = Select.app.getLocalConfiguration(
			state,
			'data.interferometry.fidColumnName',
		);
		const type = Select.insar.types.getActiveKey(state);
		const tracks =
			Select.insar.orbitalTrajectories.getActiveTrajectoriesTracks(state);

		dispatch(
			interferometryDataActions.ensureDataForMap(
				type,
				tracks,
				fidColumnName,
				forceLoad,
			),
		)
			.then(() => {
				dispatch(refreshInterferometryMapData(type, tracks));
			})
			.catch(err => {
				throw new Error(err);
			});
	};
}

function refreshInterferometryMapData(type, tracks) {
	return (dispatch, getState) => {
		const state = getState();
		const features =
			Select.insar.interferometryData.getAllFeaturesByTypeAndTrackKeysSorted(
				state,
				type,
				tracks,
			);

		const mapKey = Select.app.getLocalConfiguration(
			state,
			'data.interferometry.mapKey',
		);
		const layerKey = Select.app.getLocalConfiguration(
			state,
			'data.interferometry.layerKey',
		);

		dispatch(
			CommonAction.maps.setMapLayerOption(
				mapKey,
				layerKey,
				'features',
				features || [],
			),
		);

		dispatch(setInterferometryMapLayerStyle());

		dispatch(CommonAction.components.set('loadingOverlay', 'open', false));
		const dataModeChosen = Select.app.getLocalConfiguration(
			state,
			'data.interferometry.dataModeChosen',
		);
		const introOverlayOpen = Select.components.get(
			state,
			'introOverlay',
			'open',
		);
		if (dataModeChosen && introOverlayOpen) {
			dispatch(CommonAction.components.set('introOverlay', 'open', false));
		}
	};
}

function useInterferometryPointStatistics(featureKeys) {
	return (dispatch, getState) => {
		const state = getState();

		// expecting only one feature to be selected
		const selectedFeatureKey = featureKeys[0];

		// get featureData
		const fidColumnName = Select.app.getLocalConfiguration(
			state,
			'data.interferometry.fidColumnName',
		);
		const type = Select.insar.types.getActiveKey(state);

		const feature =
			Select.insar.interferometryData.getFeatureByTypeAndFeatureKey(
				state,
				type,
				selectedFeatureKey,
			);

		if (feature?.properties?.trackKey) {
			dispatch(
				interferometryDataActions.ensureDataForPointStatistics(
					type,
					null,
					feature.properties.trackKey,
					fidColumnName,
					selectedFeatureKey,
				),
			);
		}
	};
}

function initApp(view) {
	return dispatch => {
		const insarActions = {
			types: typesActions,
			visualizations: visualizationsActions,
			orbitalTrajectories: orbitalTrajectoriesActions,
		};

		// add view
		dispatch(CommonAction.views.add(view));

		// add metadata
		dispatch(CommonAction.attributes.add(Object.values(attributesData.byKey)));
		dispatch(
			commonActions.add(ActionTypes.ATTRIBUTE_SETS)(
				Object.values(attributeSetsData.byKey),
			),
		);
		dispatch(CommonAction.styles.add(Object.values(stylesData.byKey)));

		// add insar-specific metadata
		dispatch(typesActions.updateStore(typesData));
		dispatch(orbitalTrajectoriesActions.updateStore(orbitalTrajectoriesData));
		dispatch(visualizationsActions.updateStore(visualizationsData));

		// apply view
		dispatch(
			CommonAction.components.updateStateFromView(
				view?.data?.state?.components,
			),
		);
		dispatch(CommonAction.maps.updateStateFromView(view?.data?.state?.maps));
		dispatch(
			CommonAction.selections.updateStateFromViewWithData(
				view?.data?.state?.selections,
			),
		);
		const insarDataView = view?.data?.state?.insar;
		if (insarDataView) {
			_forIn(insarDataView, (substate, storeKey) => {
				if (Object.hasOwn(insarActions[storeKey], 'updateStateFromView')) {
					dispatch(insarActions[storeKey].updateStateFromView(substate));
				}
			});
		}

		// use data
		dispatch(useInterferometryMapData());
	};
}

function chooseDataMode(dataMode) {
	return dispatch => {
		if (dataMode) {
			dispatch(
				CommonAction.app.setLocalConfiguration(
					'data.interferometry.dataMode',
					dataMode,
				),
			);
			dispatch(useInterferometryMapData(true));
		} else {
			dispatch(CommonAction.components.set('introOverlay', 'open', false));
		}

		dispatch(
			CommonAction.app.setLocalConfiguration(
				'data.interferometry.dataModeChosen',
				true,
			),
		);
	};
}

function setInterferometryMapLayerStyle() {
	return (dispatch, getState) => {
		const state = getState();
		const mapKey = Select.app.getLocalConfiguration(
			state,
			'data.interferometry.mapKey',
		);
		const layerKey = Select.app.getLocalConfiguration(
			state,
			'data.interferometry.mapKey',
		);
		const styles = Select.insar.getActiveVisualizationStylesByActiveType(state);

		if (styles) {
			const mapLayer = Select.maps.getMapLayerStateByMapKeyAndLayerKey(
				state,
				mapKey,
				layerKey,
			);
			if (mapLayer) {
				let renderAs = _cloneDeep(mapLayer.options.renderAs);
				renderAs[0].options.style = getStyleDefinitionWithoutFilteredValues(
					state,
					styles.asPoints?.data?.definition,
				);
				dispatch(
					CommonAction.maps.setMapLayerOption(
						mapKey,
						layerKey,
						'renderAs',
						renderAs,
					),
				);
			}

			dispatch(
				CommonAction.maps.setMapLayerOption(
					mapKey,
					layerKey,
					'style',
					getStyleDefinitionWithoutFilteredValues(
						state,
						styles.asMarkers?.data?.definition,
					),
				),
			);
		}
	};
}

function setInterferometryMapBackgroundLayer(definition) {
	return (dispatch, getState) => {
		const state = getState();
		const mapKey = Select.app.getLocalConfiguration(
			state,
			'data.interferometry.mapKey',
		);
		dispatch(CommonAction.maps.setMapBackgroundLayer(mapKey, definition));
	};
}

function addInterferometryWmsLayer(layerKey) {
	return (dispatch, getState) => {
		const state = getState();
		const mapKey = Select.app.getLocalConfiguration(
			state,
			'data.interferometry.mapKey',
		);
		const layersOrder = Select.app.getLocalConfiguration(
			state,
			'data.interferometry.layersOrder',
		);
		const currentLayers = Select.maps.getMapLayersStateByMapKey(state, mapKey);
		const wmsLayers = Select.insar.getAvailableWmsLayers(state);
		const layerState = wmsLayers?.[layerKey];

		if (layerState && currentLayers) {
			const index = getLayerIndex(
				layerKey,
				layersOrder,
				currentLayers.map(layer => layer.key),
			);
			dispatch(CommonAction.maps.addMapLayerToIndex(mapKey, layerState, index));
		}
	};
}

function removeInterferometryWmsLayer(layerKey) {
	return (dispatch, getState) => {
		const state = getState();
		const mapKey = Select.app.getLocalConfiguration(
			state,
			'data.interferometry.mapKey',
		);

		dispatch(CommonAction.maps.removeMapLayer(mapKey, layerKey));
	};
}

// helpers
function getLayerIndex(layerKey, layersOrder, currentLayers) {
	const indexInOrder = layersOrder.indexOf(layerKey);
	if (currentLayers.length) {
		let i = 0;
		for (i; i < currentLayers.length; i++) {
			const currentItemIndex = layersOrder.indexOf(currentLayers[i]);
			const nextItemIndex = layersOrder.indexOf(currentLayers[i + 1]);

			if (currentItemIndex > indexInOrder) {
				return i;
			}
			if (currentItemIndex < indexInOrder && nextItemIndex >= indexInOrder) {
				return i + 1;
			}
		}
		return i;
	} else {
		return 0;
	}
}

/**
 * @param state {Object}
 * @param styleDefinition {Object} Panther style definition
 */
function getStyleDefinitionWithoutFilteredValues(state, styleDefinition) {
	const activeVisualization = Select.insar.visualizations.getActive(state);
	const filteredOutValues = activeVisualization?.data?.filteredOutValues;
	const filteredOutIntervals = activeVisualization?.data?.filteredOutIntervals;
	const filteredOutNoData = activeVisualization?.data?.filteredOutNoData;

	if (filteredOutValues) {
		let updatedDefinition = _cloneDeep(styleDefinition);
		filteredOutValues.forEach(
			value =>
				(updatedDefinition.rules[0].styles[1].attributeValues[value] =
					filteredOutStyle),
		);

		return updatedDefinition;
	} else if (filteredOutIntervals || filteredOutNoData) {
		let updatedDefinition = _cloneDeep(styleDefinition);

		const defaultStyle = updatedDefinition.rules[0].styles[0];
		if (filteredOutNoData) {
			updatedDefinition.rules[0].styles[0] = {
				...defaultStyle,
				...filteredOutStyle,
			};
		}

		updatedDefinition.rules[0].styles[1].attributeClasses =
			updatedDefinition.rules[0].styles[1].attributeClasses.map(
				attributeClass => {
					let style = {};

					if (filteredOutIntervals) {
						filteredOutIntervals.forEach(interval => {
							if (_isEqual(interval, attributeClass.interval)) {
								style = filteredOutStyle;
							}
						});
					}

					return {
						...defaultStyle,
						...attributeClass,
						...style,
					};
				},
			);

		return updatedDefinition;
	} else {
		return styleDefinition;
	}
}

export default {
	...CommonAction,
	insar: {
		types: typesActions,
		orbitalTrajectories: orbitalTrajectoriesActions,
		visualizations: visualizationsActions,
		interferometryData: interferometryDataActions,

		initApp,
		chooseDataMode,
		addInterferometryWmsLayer,
		removeInterferometryWmsLayer,
		setInterferometryMapLayerStyle,
		setInterferometryMapBackgroundLayer,
		useInterferometryMapData,
		useInterferometryPointStatistics,

		// helpers
		getLayerIndex,
	},
};
