import {createSelector} from 'reselect';
import {isEmpty as _isEmpty} from 'lodash';
import {Select as CommonSelect, commonSelectors} from '@gisatcz/ptr-state';

// selectors
import interferometryDataSelectors from './insar/InterferometryData/selectors';
import typesSelectors from './insar/Types/selectors';
import orbitalTrajectoriesSelectors from './insar/OrbitalTrajectories/selectors';
import visualizationsSelectors from './insar/Visualizations/selectors';

// data
import backgroundLayers from '../data/layers/backgroundLayers';
import wmsLayers from '../data/layers/wmsLayers';

const getAvailableBackgroundLayers = () => backgroundLayers;
const getAvailableWmsLayers = () => wmsLayers;

const getAttributeSetsByActiveType = createSelector(
	[
		commonSelectors.getAllAsObject(state => state.attributeSets),
		typesSelectors.getActiveTypeAttributeSets,
	],
	(attributeSets, activeAttributeSetKeys) => {
		if (attributeSets && activeAttributeSetKeys) {
			return activeAttributeSetKeys.map(key => {
				return attributeSets[key];
			});
		} else {
			return null;
		}
	},
);

const getDataForAttributesPanel = createSelector(
	[
		CommonSelect.attributes.getAllAsObject,
		getAttributeSetsByActiveType,
		(state, featureProperties) => featureProperties,
	],
	(attributes, attributeSets, featureProperties) => {
		if (attributes && attributeSets) {
			return attributeSets.map(attributeSet => {
				let attributesData = [];
				const attributeKeys = attributeSet?.data?.attributes;
				if (attributeKeys) {
					attributesData = attributeKeys.map(attributeKey => {
						const attributeMetadata = attributes[attributeKey]?.data;
						let value = featureProperties?.[attributeKey];

						if (value && attributeMetadata.inFormat === 'YYYYMMDD.0') {
							const dateString = value.split('.')[0];
							value = `${dateString.substring(0, 4)}-${dateString.substring(
								4,
								6,
							)}-${dateString.substring(6, 8)}`;
						}

						return {
							...attributeMetadata,
							value,
						};
					});
				}

				return {
					...attributeSet,
					attributes: attributesData,
				};
			});
		} else {
			return null;
		}
	},
);

const getSelectedPointFeature = createSelector(
	[
		CommonSelect.selections.getActive,
		interferometryDataSelectors.getAllByTypeByFeatureKey,
		typesSelectors.getActiveKey,
	],
	(activeSelection, featuresByType, activeType) => {
		const selectedFeatureKey =
			activeSelection?.data?.featureKeysFilter?.keys?.[0];
		if (selectedFeatureKey && !_isEmpty(featuresByType) && activeType) {
			return featuresByType[activeType]?.[selectedFeatureKey];
		} else {
			return null;
		}
	},
);

const getActiveVisualizationStylesByActiveType = createSelector(
	[
		visualizationsSelectors.getActive,
		typesSelectors.getActiveKey,
		CommonSelect.styles.getAllAsObject,
	],
	(visualization, type, styles) => {
		if (visualization && type && styles) {
			const styleKeysObject = visualization.data.stylesByType[type];
			return {
				asMarkers: styles[styleKeysObject.asMarkers],
				asPoints: styles[styleKeysObject.asPoints],
			};
		} else {
			return null;
		}
	},
);

const getAttributesForTooltip = createSelector(
	[
		CommonSelect.attributes.getAllAsObject,
		typesSelectors.getActiveTypeAttributesForTooltip,
	],
	(attributesMetadata, attributesForTooltip) => {
		if (attributesMetadata && attributesForTooltip) {
			return attributesForTooltip.map(key => attributesMetadata[key] || null);
		} else {
			return null;
		}
	},
);

export default {
	...CommonSelect,
	insar: {
		interferometryData: interferometryDataSelectors,
		types: typesSelectors,
		orbitalTrajectories: orbitalTrajectoriesSelectors,
		visualizations: visualizationsSelectors,

		getActiveVisualizationStylesByActiveType,
		getAvailableBackgroundLayers,
		getAvailableWmsLayers,
		getAttributesForTooltip,
		getDataForAttributesPanel,
		getSelectedPointFeature,
	},
};
