/**
 * DEFAULT CONFIG VALUES FOR ALL APP INSTANCES
 *
 * Default values only.
 * Do not use this file for development, per-instance config, etc.
 */

export default {
	interferometryDataRepository:
		'https://gisat-data.eu-central-1.linodeobjects.com/CEMS-RRM-FLEX_GST-32/app-emsn091Manila/prod/v1/vectors/interferometry',
	deploymentEnviroment: 'development', // development or production
	grafanaFaroServiceUrl:
		'https://faro-collector-prod-us-central-0.grafana.net/collect/167b1d4e437ff94e2f2ae94777c69665',
};
