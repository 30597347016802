import {connect} from '@gisatcz/ptr-state';
import Select from '../../state/Select';

import presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	return {
		data: Select.insar.getDataForAttributesPanel(
			state,
			ownProps.featureProperties,
		),
	};
};

export default connect(mapStateToProps)(presentation);
