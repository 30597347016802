import {connect} from '@gisatcz/ptr-state';
import Action from '../../../state/Action';
import Select from '../../../state/Select';

import presentation from './presentation';

const mapStateToProps = state => {
	return {
		availableVisualizations:
			Select.insar.types.getActiveTypeAvailableVisualizations(state),
		visualizations: Select.insar.visualizations.getAll(state),
		attributes: Select.attributes.getAllAsObject(state),
		activeVisualization: Select.insar.visualizations.getActive(state),
		activeStyles: Select.insar.getActiveVisualizationStylesByActiveType(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setActiveVisualization: key => {
			dispatch(Action.insar.visualizations.setActiveKey(key));
			dispatch(Action.insar.useInterferometryMapData());
		},
		handleActiveVisualizationFilteredOutValue: value => {
			dispatch(
				Action.insar.visualizations.handleActiveVisualizationFilteredOutValue(
					value,
				),
			);
			dispatch(Action.insar.setInterferometryMapLayerStyle());
		},
		handleActiveVisualizationFilteredOutNoData: isFilteredOut => {
			dispatch(
				Action.insar.visualizations.handleActiveVisualizationFilteredOutNoData(
					isFilteredOut,
				),
			);
			dispatch(Action.insar.setInterferometryMapLayerStyle());
		},
		handleActiveVisualizationFilteredOutInterval: interval => {
			dispatch(
				Action.insar.visualizations.handleActiveVisualizationFilteredOutInterval(
					interval,
				),
			);
			dispatch(Action.insar.setInterferometryMapLayerStyle());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(presentation);
